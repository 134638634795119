<script>
document.body.style.backgroundColor = 'rgb(136, 136, 136)';
document.body.style.backgroundImage = 'url("https://res.cloudinary.com/hhc2c3lq1/image/upload/v1558159198/static/inspiration-geometry_ugjexf.png")';
</script>

<style>
	@keyframes swing {
		0% { transform: rotate3d(1, 1, 1, 1.5deg); }
		50% { transform: rotate3d(1, 1, 1, -1.5deg); }
		100% { transform: rotate3d(1, 1, 1, 1.5deg); }
	}
	.header-img {
		border-radius: 5px;
		box-shadow: rgb(29, 151, 219) 1px 1px 10px;
	}
	.card {
		margin: 30px auto 30px auto;
		max-width: 300px;
		background: #b8b8b8a3;
		padding: 30px;
		border-radius: 5px;
		box-shadow: 2px 2px 10px #444444 inset;
		font-family: 'Press Start 2P', cursive;
	}
	.goofy {
		animation-duration: 3s;
		animation-name: swing;
		animation-iteration-count: infinite;
	}
	.item {
		position: relative;
		margin: 10px;
		border: 1px solid #FFFFFFAA;
		transition: border .3s;
		box-shadow: 1px 1px 10px #000000AA;
		border-radius: 5px;
		width: 140px;
	}
	.item:hover {
		box-shadow: 1px 1px 10px rgb(29, 151, 219);
	}
	.item img {
		position: relative;
		max-width: 140px;
	}
	.title-over {
		position: absolute;
		padding: 10px 0px;
		bottom: 0px;
		width: 100%;
		margin: 0 0;
		text-align: center;
		background: #FFFFFFDD;
		color: #000000AA;
		font-size: 8px;
		z-index: 5;
	}
	.project-content {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		padding: 10px 0px;
	}
</style>

<div class="card">
	<img class="header-img" style="max-width: 300px;" alt="dizyx llc" src="https://res.cloudinary.com/hhc2c3lq1/image/upload/v1563230646/static/feature_screen_yxv0du.png" />
	<h1 class="big-name" style="margin-top: 20px; font-size: 24px; letter-spacing: 16px;">projects</h1>
	<div class="project-content" style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
		<a href="https://patrickmclenithan.com" alt="Gas Station Gamblers Screen Shot">
			<div class="item goofy">
				<img alt="patrickmclenithan.com" src="https://res.cloudinary.com/hhc2c3lq1/image/upload/c_fill,e_auto_brightness,h_184,w_298,x_0,y_0/v1558152033/static/IMG_20180910_204528_ff77ux.jpg" />
				<h6 class="title-over">patrick mclenithan.com</h6>
			</div>
		</a>
		<a href="https://gsg.dizyx.com" alt="Gas Station Gamblers Screen Shot">
			<div class="item goofy">
				<img alt="Gas Station Gamblers" src="https://res.cloudinary.com/hhc2c3lq1/image/upload/v1562707751/static/app-icon_wv5kx4.png" />
				<h6 class="title-over">Gas Station Gamblers (GSG)</h6>
			</div>
		</a>
		<p style="text-align:center">
			<a style="font-size: 12px; background: rgba(29, 151, 219, .5); padding: 5px;" href="https://play.google.com/store/apps/details?id=com.dizyx.scratchermathcalifornia">GSG Android App</a>
		</p>
	</div>
	
	<pre style="margin-top: 20px;">dizyx LLC</pre>
	<pre>Los Angeles, CA 90025</pre>
	<a style="font-size: 12px;" target="_blank" href="mailto:patrick@dizyx.com">contact</a>
</div>


